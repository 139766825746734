<template>
  <section id="content">
    <title>System Savers | Home</title>
    <!-- Notification -->

    <!-- Content Row -->
    <section class="content-row content-row-color content-gamebanner">
          <div class="container">
            <header
              class="content-header content-header-large content-header-uppercase">
              <h1><mark>Laptops</mark></h1>
              <p>
                System-Savers takes old laptops, gives them new life, and gives them to you! We've got laptops for Work, Home, and School!
              </p>
              <p></p>
              <div class="product-order">
                <a
                  class="button button-secondary"
                  href="/devices/laptops"
                  target=""
                >
                  <i class="fas fa-shopping-cart icon-left"></i>See Laptops
                </a>
              </div>
            </header>
          </div>
    </section>
    <!-- Content Row -->
    <section class="content-row">
      <div class="container">
        <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="643cbc6867553cda6c2f577f" data-style-height="50px" data-style-width="300%" data-theme="dark" data-min-review-count="0" data-without-reviews-preferred-string-id="3" data-style-alignment="center">
          <a href="https://www.trustpilot.com/review/galactiq.net" target="" rel="noopener">Trustpilot</a>
        </div>
        <div class="column-row">
          <div class="column-50">
            <h3>
              <i class="fa-solid fa-vials icon-feature-inline"> </i>Fully Tested
            </h3>
            <p>
              All devices are heavily tested before even being listed on our website, so you can be sure you're getting what you pay for.
            </p>
          </div>

          <div class="column-50">
            <h3>
              <i class="fa-solid fa-shield icon-feature-inline"></i>1 Month Warranty
            </h3>
            <p>
              All devices come with a free 1 month warranty and an affordable warranty program that only costs $5/month!
            </p>
          </div>

          <div class="column-50">
            <h3>
              <i class="fa-solid fa-vault icon-feature-inline"></i>Secure Payment
            </h3>
            <p>
              100% Secure payment, we never handle your payment info ourselves
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Content Row -->
    <section class="content-row content-row-color ">
      <div class="container">
        <header class="content-header">
          <h2>
            Laptop Brands
          </h2>
        </header>
        <div class="column-row align-center-bottom">
          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="https://cdn.system-savers.com/images/logos/brands/hp.png" alt="" height="64px">
                <h4 style="margin-top: 20px">
                  HP
                </h4>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/devices/laptops/hp"
                >
                  <i class="fas fa-eye icon-left"></i>View HP Laptops
                </router-link>
              </div>
            </div>
          </div>

          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="https://cdn.system-savers.com/images/logos/brands/dell.png" alt="" height="64px">
                <h4 style="margin-top: 20px">
                  Dell
                </h4>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/devices/laptops/dell"
                >
                  <i class="fas fa-eye icon-left"></i>View Dell Laptops
                </router-link>
              </div>
            </div>
          </div>

          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="https://cdn.system-savers.com/images/logos/brands/apple.png" alt="" height="64px">
                <h4 style="margin-top: 20px">
                  Apple
                </h4>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/devices/laptops/apple"
                >
                  <i class="fas fa-eye icon-left"></i>View Apple Laptops
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="column-row align-center-bottom">
          <div class="column-33">
            <div class="product-box">
              <div class="product-header">
                <img src="https://cdn.system-savers.com/images/logos/brands/Acer-Logo.png" alt="" height="64px">
                <h4 style="margin-top: 20px">
                  Acer
                </h4>
              </div>
              <div class="product-order">
                <router-link
                  class="button button-secondary"
                  to="/devices/laptops/acer"
                >
                  <i class="fas fa-eye icon-left"></i>View Acer Laptops
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="content-row content-row-color">
      <div class="column-row align-center-bottom">
        <div class="column-90">
          <div class="container">
            <header class="content-header">
              <h2>
                Cannot find what you are looking for?
              </h2>
              <p>
                Contact one of our staff members and see if we can find
                a product for you that fits your needs.<br /><br />
                <router-link class="button button-secondary" to="/contact">
                  <i class="fas fa-envelope icon-left"></i>Get in touch
                </router-link>
              </p>
            </header>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<style scoped>
.searchMan {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.imgText {
  max-height: 200px;
}
.content-gamebanner {
  background: url(https://cdn.system-savers.com/images/banners/technician-clearing-circuit-board.png) center center no-repeat;
  background-size: cover;
  height: 250px;
  color: white;
}
</style>
<style scoped>
.loader {
    
    border: 5px solid #36373C;
    border-bottom-color: #39CC64;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.75s linear infinite;
    position: relative;
    top: 1.25px;
}

.loader-root {
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>

<script>
export default {
  name: "Home",
  components: {},
  beforeDestroy() {
    clearInterval(window.content_slider_rotation);
  },
};
</script>
<script>
export default {
    name: 'loadingSpinnerCenterLine',
    props: {
        size: {
            type: String,
            default: '24'
        },
        margin_top: {
            type: String,
            default: '0'
        }
    }
}
</script>
