import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //@ Laptops
  {
    path: "/devices/laptops",
    name: "System Savers | Laptops",
    component: () => import("../views/devices/laptops/laptops.vue"),
  },
  {
    path: "/devices/laptops/hp",
    name: "System Savers | HP",
    component: () => import("../views/devices/laptops/hp/laptops.vue"),
  },
  {
    path: "/devices/laptops/dell",
    name: "System Savers | Dell",
    component: () => import("../views/devices/laptops/dell/laptops.vue"),
  },
  {
    path: "/devices/laptops/acer",
    name: "System Savers | Acer",
    component: () => import("../views/devices/laptops/acer/laptops.vue"),
  },
  {
    path: "/devices/laptops/alienware",
    name: "System Savers | Alienware",
    component: () => import("../views/devices/laptops/alienware/laptops.vue"),
  },
  {
    path: "/devices/laptops/apple",
    name: "System Savers | Apple",
    component: () => import("../views/devices/laptops/apple/laptops.vue"),
  },
  {
    path: "/devices/laptops/gateway",
    name: "System Savers | Gateway",
    component: () => import("../views/devices/laptops/gateway/laptops.vue"),
  },
  {
    path: "/devices/laptops/msi",
    name: "System Savers | MSI",
    component: () => import("../views/devices/laptops/msi/laptops.vue"),
  },
  //@ Auctions
  {
    path: "/hosting/auctions",
    name: "Auctions",
    component: () => import("../views/auctions/auctions.vue"),
  },
  //@ Galactiq
  {
    path: "/terms-of-service",
    name: "Terms of Service",
    component: () => import("../views/galactiq/terms-of-service.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/galactiq/privacy-policy.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/galactiq/contact.vue"),
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/galactiq/team.vue"),
  },
  //@ Login
  {
    path: "/login",
    name: "Services Login",
    component: () => import("../views/login/login.vue"),
  }, 
  {
    path: "/redirects/billing",
    name: "Billing",
    component: () => import("../views/login/billing.vue")
  },
  {
    path: "/redirects/pterodactyl",
    name: "Pterodactyl",
    component: () => import("../views/login/pterodactyl.vue")
  },
  //@ Errors
  {
    path: "*",
    name: "404 not found",
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;